
.btn-request {
    color: white !important;
    font-weight: bold !important;
    background-color: #50D3FF !important;
}

.bg-request {
    border-radius: 10px;
    padding: 2rem;
    background-color: #393DA1;
    /* background-image: ; */
}

.request-image {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 19rem;
}

.image-container {
    position: absolute;
    bottom: 0;
    left: 27%;
}

.request-container {
    position: relative;
    margin-top: 100px;
}

.request-image-circle {
    position: absolute;
    z-index: -111;
    bottom: -20%;
    right: -5%;
}

.request-image-circle-small {
    position: absolute;
    z-index: -111;
    /* bottom: -20%; */
    right: -6%;
}


.request-button {
    font-weight: 600;
    background-color: #50D3FF !important;
    color: white !important;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .bg-request {
        border-radius: 10px;
        padding: 1rem;
        background-color: #393DA1;
        /* background-image: ; */
    }
}
