.switchItem {
    cursor: pointer;
    padding: 0.3rem;
}

.switchItem.active {
    background-color: #E3F0FF;
}

.border-lingrad-blue {
    --tw-border-opacity: 1;
    border-color: linear-gradient(217.2deg, #0F137F 14.29%, #5CBFDE 85.66%) / var(--tw-border-opacity);
}

.rounded-circle {
    border-radius: 10%;
}

.img-navbar {
    background: url('../images/bgnavbar.png');
    border-radius: 0 0 200px 200px;
}

.underline-left-to-right {
    position: relative;
}

.underline-left-to-right::before {
    content: '';
    position: absolute;
    bottom: -.15em;
    left: 0;
    width: 0;
    height: .15em;
    background-color: #393DA1;
    transition: width 0.3s ease;
}

.underline-left-to-right:hover::before {
    width: 75%;
}

.circle-container {
    position: relative;
    border: 5px solid #50D3FF;
    padding: 25px;
    border-radius: 50%;
    width: 500px;
}

.intersect-img {
    position: relative;
    margin-top: -70px;
    margin-right: 30px;
    z-index: 1;
}

/* .header-circle-back {
    width: 430px;
    height: 440px;
    border-radius: 50%;
    background-color: #393DA1;
    position: absolute;
    margin-bottom: 10px;
    z-index: 0;
} */

.headerDescription {
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: left;
    margin-bottom: 50px;
}

.header-button {
    font-weight: 600;
    background-color: #393DA1 !important;
    color: white !important;
}

.header-title {
    font-weight: 600;
    color: black;
    text-align: left;
}

.header-footer {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #393DA1;
    /* margin-top: 80px; */
    padding: 30px 30px;
}

.header-footer-img {
    border-radius: 10%;
    background-color: #FFFFFF33;
    position: relative;
    margin: 0 10px;
    padding: 10px 0;
}

.header-footer-content {
    text-align: left;
}

@media (max-width: 768px) {
    .img-navbar {
        border-radius: 0;
    }

    .header-footer-img {
        border-radius: 15px;
        background-color: #FFFFFF33;
        position: relative;
        margin: 0 10px;
        padding: 10px 0;
    }
}

.donut-card {
    border-radius: 20px;
    border: 1px solid #CACACA;
}

.donut-request-button {
    font-weight: 600;
    background-color: #393DA1 !important;
    color: white !important;
    border-radius: 10px;
}

.sidebar-toggle {
    cursor: pointer;
}

.container-dashboard {
    width: 100%;
    padding-inline: 16px;
}

@media (min-width: 475px) {
    .container-dashboard {
        max-width: 475px;
    }
}

@media (min-width: 640px) {
    .container-dashboard {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container-dashboard {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container-dashboard {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container-dashboard {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container-dashboard {
        max-width: 1536px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}