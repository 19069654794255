.map-card {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 400px !important;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 25px 0px #0000001A;
}

.map-card-container {
    position: relative;
}

/*test*/