.circular-progress {
    position: relative;
    display: inline-block;
}

.circular-progress-circle {
    position: relative;
    width: 370px;
    height: 370px;
    background: transparent;
    border-radius: 50%;
    /*overflow: hidden;*/
}

.segment {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 250px;
    transform-origin: 0 0;
}

/*
.circular-progress-circle {
    pointer-events: none;
} */

.circular-progress-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px;
    height: 500px;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 50%;
    box-shadow: inset rgba(34, 36, 38, 0.15) 0px 0px 3px 2px;
}

.circular-progress-value {
    position: absolute;
    font-size: 400%;
    font-family: Roboto;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.s1 {
    background: #50D3FF;
    transform: rotate(0deg) skew(0deg)
}

.s2 {
    background: #EB5652;
    transform: rotate(90deg) skew(0deg)
}

.s3 {
    background: #FFD542;
    transform: rotate(180deg) skew(0deg)
}

.s4 {
    background: #47DAAC;
    transform: rotate(270deg) skew(0deg)
}

/*.s1:hover {*/
/*    transform: rotate(0deg) skew(0deg) translate(20px, 20px);*/
/*    transition: transform 0.7s ease 0.3s; !* Добавим плавный переход с задержкой 0.3s *!*/
/*}*/

.s2:hover {
    transform: rotate(90deg) skew(0deg) translate(20px, 20px);
    transition: transform 0.7s ease 0.3s;
}

.s3:hover {
    transform: rotate(180deg) skew(0deg) translate(20px, 20px);
    transition: transform 0.7s ease 0.3s;
}

.s4:hover {
    transform: rotate(270deg) skew(0deg) translate(20px, 20px);
    transition: transform 0.7s ease 0.3s;
}

.asd {
    pointer-events: none;
}
