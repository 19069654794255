.login-container {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.login-container.hidden {
    opacity: 0;
    display: none;
}

.main-container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    /* width: 768px; */
    width: 100%;
    max-width: 100%;
    min-height: 580px;
}

.main-container p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

/*
.main-container span{
    font-size: 12px;
} */

.main-container a {
    text-decoration: none;
    margin: 15px 0 10px;
}

.main-container button {
    /* background-color: #512da8; */
    /* color: #fff; */
    /* font-size: 12px; */
    /* padding: 10px 45px; */
    border: 1px solid transparent;
    /* border-radius: 8px; */
    /* font-weight: 600; */
    /* letter-spacing: 0.5px; */
    /* text-transform: uppercase; */
    /* margin-top: 10px; */
    cursor: pointer;
}

.main-container button.hidden {
    background-color: transparent;
    border-color: #fff;
}

.main-container form {
    background-color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.main-container input {
    /* background-color: #eee; */
    /* border: none; */
    /* margin: 8px 0; */
    /* padding: 10px 15px; */
    /* font-size: 13px; */
    /* border-radius: 8px; */
    width: 100%;
    outline: none;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.main-container.active .sign-in {
    transform: translateX(100%);
}

.sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.main-container.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }
    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    /* border-radius: 150px 0 0 100px; */
    z-index: 1000;
}

.main-container.active .toggle-container {
    transform: translateX(-100%);
    /* border-radius: 0 150px 100px 0; */
}

.toggle {
    /* background-color: #512da8; */
    height: 100%;
    /* background: linear-gradient(to right, #5c6bc0, #512da8); */
    color: #fff;
    position: relative;
    left: -100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.main-container.active .toggle {
    transform: translateX(50%);
}

.toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left {
    transform: translateX(-200%);
}

.main-container.active .toggle-left {
    transform: translateX(0);
}

.toggle-right {
    right: 0;
    transform: translateX(0);
}

.main-container.active .toggle-right {
    transform: translateX(200%);
}


.background-image {
    background: url('../images/login_back.png') no-repeat;
    border-radius: 2%;
    background-size: cover;
}

.bg-login-banner {
    border: 1px solid;
    border-image-source: radial-gradient(102.1% 102.1% at 17.33% 0%, #FFFFFF 0%, rgba(228, 228, 228, 0.750969) 63.85%, rgba(255, 255, 255, 0.61) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}