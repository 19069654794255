.sidebar {
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
}

.sidebar a {
    color: #fff;
    text-decoration: none;
}

.sidebar.open {
    left: 0; /* отображаем меню при открытии */
}
