.activeBullet {
    background: #393DA1;

}

.bullet {
    border-radius: 50%;
    z-index: 10;
}

.swiper-pagination-bullet-active {
    background: #393DA1;
    border-radius: 50%;
    z-index: 10;
}

.slide-bg {
    background-image: url('../images/carouselBg.jpg');
}