.uniqueness-circle-small-top {
    position: absolute;
    top: 0;
    left: 18%;
}

.arrow-image {
    position: absolute;
    top: -60%;
    right: -14%;
}

.uniqueness-circle {
    position: absolute;
    z-index: -111;
    top: 18%;
    right: -3%;
}

.uniqueness-circle-small-bottom {
    position: absolute;
    bottom: 0;
    right: -14%;
}