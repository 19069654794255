.banner-circle {
    position: absolute;
    left: -10%;
    top: -10%;
    z-index: -111;
}

.banner-circle-small {
    position: absolute;
    right: 45%;
    top: -10%;
    z-index: -111;
}
